/*
 * @Description:
 * @version: V1.0
 * @Author: 张宇童
 * @Date: 2020/9/6 14:00
 * @LastEditors: 张宇童
 * @LastEditTime: 2020/9/6 14:00
 */
export default [
	{
		id: 1,
		title: "收银清单",
		path: "/admin/list",
		icon: "mdi-home",
	},
	{
		id: 2,
		title: "项目管理",
		path: "",
		icon: "mdi-view-dashboard",
		children: [
			{
				id: 201,
				title: "项目列表",
				path: "/home/projectManage",
				icon: "mdi-tag",
			},
			// {
			//   id: 202,
			//   title: '新建项目',
			//   path: '/home/newProject',
			//   icon: 'mdi-star'
			// },
		],
	},
	{
		id: 3,
		title: "用户管理",
		path: "",
		icon: "mdi-account",
		children: [
			{
				id: 301,
				title: "用户列表",
				path: "/home/users",
				icon: "mdi-star",
			},
			{
				id: 302,
				title: "用户组列表",
				path: "/home/usergroups",
				icon: "mdi-star",
			},
			{
				id: 303,
				title: "个人中心",
				path: "/home/userhome/1",
				icon: "mdi-star",
			},
		],
	},
	{
		id: 4,
		title: "精品列表",
		path: "/admin/BoutiqueList",
		icon: "mdi-cog",
	},
	{
		id: 5,
		title: "库存列表",
		path: "/admin/stockList",
		icon: "mdi-cog",
	},
	{
		id: 6,
		title: "保险业务",
		path: "/admin/InsuranceInput",
		icon: "mdi-cog",
	},
];
