<template>
	<v-list-item :to="{ path: menuInfo.path }" link>
		<v-list-item-icon class="mr-2 ml-1">
			<v-icon color="primary" small>{{ menuInfo.icon }}</v-icon>
		</v-list-item-icon>
		<v-list-item-title>{{ menuInfo.title }}</v-list-item-title>
	</v-list-item>
</template>

<script>
	export default {
		name: "MenuItem",
		props: {
			menuInfo: {
				type: Object,
				default: () => ({}),
			},
		},
	};
</script>
