const env_mode = process.env.NODE_ENV === 'production' ? true : false;
const config = {
    production: env_mode,
    global: {
        title: '测试项目',
        copyright: '东风南方汽车销售服务有限公司',
        year: '2020'
    },
}
module.exports = config;
