<template>
	<v-app>
		<!-- 顶部标题栏和左侧菜单栏 -->
		<app-frame />

		<!-- 右侧主工作区。请注意主工作区要放到 v-main 标签中。v-main 标签使用正常的 css 定位, 不使用 fixed, absolute 定位。
      而带有 app 属性的 vuetify 组件，采用 fixed, absolute 定位，不能房子 v-main 中。例如 v-app-bar, v-navigation-drawer 等。 -->
		<v-main>
			<v-container fluid>
				<router-view></router-view>
			</v-container>
		</v-main>

		<!-- v-alert 组件似乎有bug, dense 属性不起作用。并且默认不是 fixed 或者 absolute 定位，不进行处理的话，会挤占别的组件位置。 -->
		<v-alert
			v-model="alert"
			dense
			dismissible
			max-height="50"
			style="position:fixed;z-index:9;width:70%;margin-left:150px;margin-top:250px;padding-top:10px;padding-bottom:40px;"
			type="warning"
		>
			{{ alertContent }}
		</v-alert>
		<v-footer app>
			<div class="text-center" style="width:100%">©{{config.global.year}} {{config.global.copyright}} 版权所有</div>
		</v-footer>
	</v-app>
</template>

<script>
	import AppFrame from "./app-frame";
	import config from '@/../project.config.js'

	export default {
		name: "Index",
		components: {
			AppFrame,

		},

		data: () => ({
			alert: false,
			alertContent: "",
			config
		}),

		mounted() {
			// console.log(config, 'ss')

		}


	};
</script>
