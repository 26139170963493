<template>
	<v-list-group no-action>
		<template v-slot:activator>
			<v-list-item-icon class="mr-2 ml-1">
				<v-icon color="primary" small>{{ menuInfo.icon }}</v-icon>
			</v-list-item-icon>
			<v-list-item-title>{{ menuInfo.title }}</v-list-item-title>
		</template>
		<v-list-item v-for="item of menuInfo.children" :key="item.id" :to="{ path: item.path }" link>
			<v-list-item-title>{{ item.title }}</v-list-item-title>
		</v-list-item>
	</v-list-group>
</template>
<script>
	export default {
		name: "MenuChildItem",
		props: {
			menuInfo: {
				type: Object,
				default: () => ({}),
			},
		},
	};
</script>
